
























































import {
  defineComponent,
  computed,
  PropType
} from '@nuxtjs/composition-api'
import type { ProductWithRent } from '@wemade-vsf/rentproduct';
import { prepareCardProduct } from '@wemade-vsf/catalog/lib/runtime/product/helpers/prepareCardProduct';
import { useImage } from '@wemade-vsf/composables';
import ProductPrice from 'components/catalog/Product/ProductPrice.vue';

export default defineComponent({
  name: 'ProductCard',
  props: {
    product: {
      type: Object as PropType<ProductWithRent>,
      required: true
    },
    showPrice: {
      type: Boolean,
      default: true
    },
    showActions: {
      type: [Boolean, Array],
      default: false
    }
  },
  components: {
    ProductPrice,
    ProductCardActions: () => import('components/catalog/Product/ProductCard/Actions.vue'),
    ProductLabels: () => import('components/catalog/Product/ProductLabels.vue')
  },
  setup(props) {
    const { imageSizes: { productCard: imageSize } } = useImage();
    const cardProduct = prepareCardProduct(props.product)
    const { title, image, link, nuxtImgConfig, specialPrice, regularPrice, hasPriceRange } = cardProduct.cardProps
    const { sku } = cardProduct
    const hasLabels = !!props.product.product_labels?.length
    const availableActions = ['link', ...cardProduct.cardProps.showCartButton ? ['cart'] : []];

    const isRentProduct = computed(() => props.product?.rentproduct?.renting_type ? ['rent', 'rent_and_buy'].includes(props.product.rentproduct.renting_type) : false)
    const isBuyProduct = computed(() => props.product?.rentproduct?.renting_type ? ['rent_and_buy', 'buy'].includes(props.product.rentproduct.renting_type) : true)
    const rentingPrice = computed(() => props.product?.rentproduct?.renting_display_price)

    return {
      cardProduct,
      image,
      imageSize,
      isBuyProduct,
      isRentProduct,
      link,
      nuxtImgConfig,
      regularPrice,
      sku,
      specialPrice,
      title,
      hasPriceRange,
      hasLabels,
      availableActions,
      rentingPrice
    }
  }
})
